.card-highlight-success {
    border: 3px solid rgb(120, 222, 120); /* green */
  }
  
  .card-highlight-failure {
    border: 3px solid rgb(255, 0, 0); /* red */
  }

.my-custom-class {
    --width: 250px;
    --height: 200px;
  }
  
  
  .my-custom-textarea {
    max-height: 200px;
    border: 1px solid black;
  }

  .description {
    font-size: 0.7em; /* Adjust as needed */
    font-weight: normal;
  }

  .small-input {
    width: 25px; /* Adjust this value to your needs */
  }

  .no-padding-top {
    --ion-padding: 0;
  }

  .error-message {
    color: red;
  }