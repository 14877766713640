.calendar-container .fc-toolbar-title {
    font-size: 18px;
}

.calendar-container .fc-button {
    font-size: 14px;
}

.calendar-container .fc {
    border: 1px solid #000;
    border-radius: 10px;
}